import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import "../style/main.scss"
const Success = () => {
  const data = useStaticQuery(graphql`
    query {
      contactImage: file(relativePath: { eq: "brassens_white.png" }) {
        childImageSharp {
          fluid(maxHeight: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <section className="success">
      <div className="success__content">
        <div className="success__content__logo">
          <Img fluid={data.contactImage.childImageSharp.fluid} />
        </div>
        <h2>Merci pour votre message&nbsp;!</h2>
        <p>J’y répondrai très prochainement.</p>
        <p>A bientôt&nbsp;!</p>
        {/* <Link className="success__content__goback" to="/">
          <FontAwesomeIcon className="overlay__icon" icon={faChevronLeft} />
        </Link> */}
        <Link to="/">
          <div className="btn-back success__content__arrow">
            <span className="btn-back__arrow"></span>
          </div>
        </Link>
      </div>
    </section>
  )
}

export default Success
